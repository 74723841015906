import styled, { css } from 'styled-components';

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Heading = styled.h2`
  line-height: 24px;
  margin: auto 0 auto 0;
`;

export const BlueIcon = styled.i`
  margin: auto auto;
  padding: 0 1rem 0 0;
  color: var(--anchor-color);
`;
export const HeaderWithButtonText = styled.h1``;

export const SectionHeading = styled.h2`
  display: flex;
`;

export const ButtonRow = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
`;

export const ButtonRowNoWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  white-space: nowrap;
`;

export const CenteredButtonRow = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`;

export const Hr = styled.hr`
  margin-top: 1rem;
  width: 100%;
`;

export const Label = styled.strong`
  color: #495057;
  font-size: 1rem;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  flex-wrap: nowrap;
`;
export const Value = styled.div`
  width: 100%;
  margin-top: 0.3rem;
  flex-grow: 1;
`;

export const Clickable = styled.div`
  display: inline-block;
  cursor: pointer;
  color: var(--anchor-color);
`;

export const RingilHeading = styled.h1`
  font-size: 1.5rem;
`;

export const RingilH2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const RingilH3 = styled.h3`
  font-size: 1.165rem;
`;

export const RingilLabel = styled.h1`
  font-size: 1rem;
`;

export const Row = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
`;

export const RowWithMargin = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
  margin: 1rem 0;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColWithGap = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
  flex-direction: column;
`;
export const ColWithSmallerGap = styled.div`
  display: flex;
  gap: var(--ringil-red-gap);
  flex-direction: column;
`;

export const CenteredRowWithGap = styled.div`
  display: flex;
  column-gap: var(--ringil-cyan-gap);
  align-items: center;
`;

export const CenteredColWithGap = styled.div`
  display: flex;
  column-gap: var(--ringil-cyan-gap);
  align-items: center;
  flex-direction: column;
`;

export const RowWithGap = styled.div`
  display: flex;
  column-gap: var(--ringil-cyan-gap);
  align-items: center;
`;

export const RowWithIcon = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
  align-items: center;
`;

export const RowWithSpaceBetween = styled.div<{ alignItems: string; margin: string }>`
  display: flex;
  gap: var(--ringil-cyan-gap);
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: space-between;
  ${(props) =>
    props &&
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const RingilSectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--ringil-base-tertiary-color);
  padding: 1rem;
  margin: 0.5rem -1rem;
  container-type: inline-size;

  &:container (min-width: 500px) {
    background-color: red;
  }
`;

export const RingilSubsectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0.5rem -1rem;
  background-color: #e9ecef;
`;

export const Note = styled.div`
  display: flex;
  font-size: small;
  color: gray;
`;

export const WithGap = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const NoWrapText = styled.div`
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const RowForTagsAndBadges = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--ringil-cyan-gap);
  justify-content: space-between;
  align-items: center;
`;
