import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import { ApplicationContext } from '../ApplicationContext/ApplicationContext';
import useTranslationLgs from '../../hooks/i18n/useTranslation';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'next-i18next';
import { useStateCallback } from '../../hooks/useStateCallback/useStateCallback';

interface I18NContextType {
  tr: (translationKey: string, defaultValue: string, data?: { [key: string]: any }, count?: number) => string;
  translationLoaded: boolean;
  translateModel: (modelPrefix: string, key: string) => string;
}

export const I18NContext = React.createContext<I18NContextType>(undefined);

const I18NProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { t, i18n } = useTranslation('common');
  const { cuGetCustomerTranslations } = useContext(ApiContext);
  const [i18nData, setI18nData] = useStateCallback(null);
  const [translationLoaded, setTranslationLoaded] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      cuGetCustomerTranslations((d) => {
        setI18nData(d, (state) => setTranslationLoaded(true));
      });
    }
  }, [isAuthenticated]);

  const interpolate = (key: string, lng: string, i18nData): string => {
    const k = `${key}/${lng}`;
    const v = i18nData[k];
    return v ? v : k;
  };

  const translateModel = (modelPrefix: string, key: string) => {
    if (translationLoaded) {
      const companySpecific = interpolate(`model/${modelPrefix}/${key}`, i18n.language, i18nData);
      return companySpecific ? companySpecific : t(key, key);
    } else {
      return t(key, key);
    }
  };

  const tr = (
    translationKey: string,
    defaultValue: string,
    data: { [key: string]: any } = {},
    count: number = undefined,
  ) => {
    // company specific data
    return t(translationKey, defaultValue, { count, replace: data });
  };

  return <I18NContext.Provider value={{ tr, translationLoaded, translateModel }}>{children}</I18NContext.Provider>;
};

export default I18NProvider;
