import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ApiContext } from '../../api/api';
import i18next from 'i18next';
import { useStateCallback } from '../useStateCallback/useStateCallback';
import { useAuth0 } from '@auth0/auth0-react';
import { I18NContext } from '@components/contexts/I18NProvider';
import { useRouter } from 'next/router';

export enum CUSTOMER_CONTENT {
  MODEL = 'model',
}

const useTranslationLgs = () => {
  const { translationLoaded, translateModel, tr } = useContext(I18NContext);
  const router = useRouter();

  const showNames = (router.query['i18nShowNames'] as string) === 'true';

  return {
    tr: (key: string, defaultText: string = key, data?: any, count?: number) => {
      if (showNames) {
        return `${key}:${tr(key, defaultText, data, count)}`;
      } else {
        return tr(key, defaultText, data, count);
      }
    },
    translationLoaded,
    translateModel,
  };
};

export default useTranslationLgs;
