import classNames from 'classnames';
import { Button } from 'primereact/button';

interface ComponentProps {
  backgroundWrapper?: string;
  iconName?: string;
  buttonName?: string;
  onClick?: any;
}

const AlertInfo: React.FC<ComponentProps> = ({ children, backgroundWrapper, iconName, buttonName, onClick }) => {
  const className = classNames('alert-info', {
    'bg-orange': backgroundWrapper === 'orange',
    'bg-orange2': backgroundWrapper === 'orange2',
    'bg-cyan': backgroundWrapper === 'cyan',
    'bg-transparent': backgroundWrapper === 'transparent',
    'bg-pink': backgroundWrapper === 'pink',
    'bg-red': backgroundWrapper === 'red',
  });

  return (
    <div className={className}>
      <div className="alert-description p-d-flex">
        {iconName && (
          <div className="alert-icon">
            <span className="material-icons"></span>
          </div>
        )}
        <div className="alert-text">{children}</div>
        {buttonName && (
          <div className="alert-button">
            <Button label={buttonName} className="p-button-outlined" onClick={onClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertInfo;
